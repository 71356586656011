import moment from "moment";
import MonthCalendar from "../calendar/MonthCalendar";

function IncidentCalendar({ year, incidents }) {

  const mIncidents = new Array(12).fill([])
  incidents.forEach(incident => {
    const month = moment(incident['date']).month()
    mIncidents[month] = [...mIncidents[month], incident]
  });

  return (
    <div className="grid grid-cols-6 gap-4 my-8">
      {mIncidents.map((i, m) => <MonthCalendar key={m} year={year} month={m} incidents={i} />)}
    </div>
  )
}

export default IncidentCalendar