import moment from "moment";
import NumberFormat from "react-number-format";
import TooltipCircle from "./TooltipCircle";

const headClass =
  "border-b dark:border-slate-400 font-medium p-2 text-slate-400 dark:text-slate-200 text-center";
const cellClass =
  "border-b border-slate-100 dark:border-slate-700 p-2 text-slate-500 dark:text-slate-400";

function stats(monthDate, incidents) {
  if (moment().startOf("month").diff(monthDate, "month") < 0) return "-";

  let minutesInMonth = moment(monthDate).daysInMonth() * 24 * 60;
  // if (moment().diff(monthDate, "month") === 0) {
  //   minutesInMonth = moment().date() * 24 * 60;
  // }

  const downtime = incidents.reduce((sum, o) => sum + o["minutes"], 0);
  const uptime = ((minutesInMonth - downtime) / minutesInMonth) * 100;
  return { uptime, downtime };
}

function Heading({ month, uptime }) {
  return (
    <div className="text-xl font-medium" data-testid="month-heading">
      <span className="text-slate-800 mr-2">{month}</span>
      <NumberFormat
        value={uptime}
        suffix="%"
        decimalScale={3}
        displayType="text"
        className="text-slate-400"
      />
    </div>
  );
}

function CellWrapper({ cell }) {
  if (cell) {
    if (moment().isBefore(cell.dt)) {
      // future
      return (<div className="w-4 h-4 rounded-full bg-slate-200" />)
    } else {
      return (<TooltipCircle dt={cell.dt} incidents={cell.incidents} />)
    }
  } else {
    // empty
    return (<div className="w-4 h-4 rounded-full bg-slate-100" />)
  }
}

function Cell({ cell }) {

  return (
    <td className={cellClass}>
      <CellWrapper cell={cell} />
    </td>
  );
}

function Row({ row }) {
  return (
    <tr>
      {row.map((cell, k) => (
        <Cell key={k} cell={cell} />
      ))}
    </tr>
  );
}

function MonthCalendar({ year, month, incidents }) {

  const monthDate = new Date(year, month);
  let firstDay = monthDate.getDay() - 1;
  if (firstDay === -1) {
    firstDay = 6;
  }
  const daysInMonth = 31 - new Date(year, month, 32).getDate();
  const nameOfMonth = moment(monthDate).format("MMM");
  const { uptime } = stats(monthDate, incidents);

  let day = 0;

  function generateRow(i) {
    return [...Array(7)].map((o, j) => {
      if (i === 0 && j < firstDay) {
        return null;
      } else if (day > daysInMonth) {
        return null;
      } else {
        day++;
        const dt = moment(`${year}-${month+1}-${day}`, "YYYY-MM-DD")
        const dIncidents = incidents.filter(
          (incident) => dt.isSame(incident.date, 'day')
        );
        return {
          dt,
          incidents: dIncidents,
        };
      }
    });
  }

  function generateTable() {
    return [...Array(6)].map((o, i) => generateRow(i));
  }

  const table = generateTable();

  return (
    <div>
      <Heading month={nameOfMonth} uptime={uptime} />
      <table className="table-fixed w-full text-sm bg-slate-100 rounded-xl">
        <thead>
          <tr>
            <th className={headClass}>M</th>
            <th className={headClass}>T</th>
            <th className={headClass}>W</th>
            <th className={headClass}>T</th>
            <th className={headClass}>F</th>
            <th className={headClass}>S</th>
            <th className={headClass}>S</th>
          </tr>
        </thead>
        <tbody>
          {table.map((r, k) => (
            <Row key={k} row={r} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MonthCalendar;
