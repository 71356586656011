import moment from "moment";
import parse from "html-react-parser";
import { formatTimespan } from "../../utils";

const headClass = "border-b dark:border-slate-400 font-medium p-4 text-slate-400 dark:text-slate-200 text-left";
const cellClass = "border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400";

function Heading({ year }) {
  return (
    <div className="text-xl font-semibold" data-testid="incident-list-heading">
      <span className="text-slate-800 mr-2">{year}</span>
      <span className="text-slate-400">Incidents</span>
    </div>
  );
}

function Circle({ color }) {
  return <div className={`w-4 h-4 rounded-full ${color}`} />;
}

function TableRow({ incident }) {
  return (
    <tr>
      <td className={cellClass}>{moment(incident["date"]).format("MMM DD")}</td>
      <td className={cellClass}>
        {incident["partial"] ? (
          <Circle color="bg-yellow-400" />
        ) : (
          <Circle color="bg-red-400" />
        )}
      </td>
      <td className={cellClass}>{formatTimespan(incident["minutes"])}</td>
      <td className={cellClass} colSpan={4}>{parse(incident['details'])}</td>
    </tr>
  );
}

function IncidentList({ year, incidents }) {
  return (
    <>
      <div className="my-5">
        <Heading year={year} />
      </div>

      <div className="my-5">
        <table className="table-fixed w-full text-sm bg-slate-100 rounded-xl">
          <thead>
            <tr>
              <th className={headClass}>
                Date
              </th>
              <th className={headClass}>
                Level
              </th>
              <th className={headClass}>
                Duration
              </th>
              <th className={headClass} colSpan={4}>
                Description
              </th>
            </tr>
          </thead>
          <tbody className="bg-slate-50 dark:bg-slate-800">
            {incidents.map((i, k) => (
              <TableRow key={k} incident={i} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default IncidentList;
