export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function formatTimespan(minutes) {
  // if (!minutes) return "";
  if (minutes < 60) return `${minutes}m`;
  let duration = minutes;
  let hours = duration / 60;
  let min = duration % 60;
  return `${parseInt(hours, 10)}h ${min}m`;
}