import moment from "moment";
import NumberFormat from "react-number-format";
import IncidentList from "./IncidentList";
import IncidentCalendar from "./IncidentCalendar";
import { incidents } from "../../data";

function stats(year) {
  const startOfYear = moment(`${year}-01-01`).startOf("year");
  const endOfYear = moment(`${year}-01-01`).endOf("year");
  const minutesInYear = moment(endOfYear).diff(startOfYear) / 60 / 1000;

  const filtered = incidents.filter((o) =>
    moment(o["date"]).isSame(startOfYear, "year")
  );

  const downtime = filtered.reduce((sum, o) => sum + o["minutes"], 0);
  const uptime = ((minutesInYear - downtime) / minutesInYear) * 100;

  return { filtered, uptime };
}

function Heading({ year, uptime }) {
  return (
      <div className="text-2xl font-semibold" data-testid="year-heading">
        <span className="text-slate-800 mr-2">{year}</span>
        <NumberFormat
          value={uptime}
          suffix="%"
          decimalScale={3}
          displayType="text"
          className="text-slate-400"
        />
      </div>
  )
}

function YearWrapper({ year }) {
  const { filtered, uptime } = stats(year);

  return (
    <>
      <Heading year={year} uptime={uptime} />
      <IncidentCalendar year={year} incidents={filtered} />
      <IncidentList year={year} incidents={filtered} />
    </>
  );
}

export default YearWrapper;
