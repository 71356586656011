import { Tab } from "@headlessui/react";
import { years } from "../data";
import { classNames } from "../utils";
import IncidentsWrapper from "./incidents/YearWrapper";

function TabWrapper({ label }) {
  return (
    <Tab
      key={label}
      className={({ selected }) =>
        classNames(
          "w-full py-2.5 text-sm leading-5 font-medium rounded-lg",
          "focus:outline-none",
          selected
            ? "bg-white shadow text-slate-800"
            : "text-slate-400 hover:bg-white/[0.12] hover:text-white"
        )
      }
    >
      {label}
    </Tab>
  );
}

function Tabs() {
  return (
    <Tab.Group defaultIndex={years.length - 1}>
      <Tab.List
        data-testid="tab-list"
        className="flex p-1 space-x-1 bg-slate-800 rounded-xl my-5"
      >
        {years.map((year, key) => (
          <TabWrapper key={key} label={year} />
        ))}
      </Tab.List>
      <Tab.Panels data-testid="tab-panels">
        {years.map((year, key) => (
          <Tab.Panel key={key}>
            <IncidentsWrapper year={year} />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}

export default Tabs;
