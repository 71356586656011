import moment from "moment";
import logo from "../logo.svg";

function Status({ color, label }) {
  return (
    <div className="flex items-center mr-2">
      <div className={`w-4 h-4 rounded-full ${color}`} />
      <span className={`text-sm ml-1 text-slate-600`}>{label}</span>
    </div>
  );
}

function Header() {
  const date = moment().format("YYYY.MM.DD");
  return (
    <header>
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-semibold text-slate-800">
          Performance & Incident Reporting
        </h1>
        <img src={logo} alt="Logo" className="float-end w-32" />
      </div>
      <div className="flex justify-between">
        <p className="text-sm uppercase text-slate-600">Generated on {date}</p>
        <div className="flex">
          <Status color="bg-green-400" label="AVAILABLE" />
          <Status color="bg-yellow-400" label="REDUCED PERFORMANCE" />
          <Status color="bg-red-400" label="UNAVAILABLE" />
        </div>
      </div>
    </header>
  );
}

export default Header;
