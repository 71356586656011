export const years = ["2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024"]

export const incidents = [
  { 
    date: "2015-02-10T23:00:00.000Z",
    minutes: 13,
    details: "Reduced availability and increased latency issues within the EU region.",
    partial: true,
  },
  {
    date: "2015-02-15T23:00:00.000Z",
    minutes: 1,
    details: "Maintenance on databases.",
    partial: true,
  },
  {
    date: "2015-05-31T22:00:00.000Z",
    minutes: 11,
    details: "Automated systems have detected routing errors.",
    partial: true,
  },
  {
    date: "2015-06-30T22:00:00.000Z",
    minutes: 35,
    details: "Network errors due to issues with a upstream internet provider.",
    partial: false,
  },
  {
    date: "2015-07-22T22:00:00.000Z",
    minutes: 76,
    details: "Connectivity issues. Slow down due to peering issues.",
    partial: true,
  },
  {
    date: "2015-09-22T22:00:00.000Z",
    minutes: 11,
    details: "Router is having a problem sending requests, resulting in increased request queueing time, H19 errors, or H21 errors.",
    partial: true,
  },
  {
    date: "2015-11-19T23:00:00.000Z",
    minutes: 107,
    details: "Connection errors with service provider.",
    partial: true,
  },
  //
  {
    date: "2016-01-11T23:00:00.000Z",
    minutes: 6,
    details: "Drop in overall routing availability. This might result in closed connections or other errors.",
    partial: true,
  },
  {
    date: "2016-03-21T23:00:00.000Z",
    minutes: 60,
    details: "Higher latencies and some failing HTTP requests, both for incoming and outgoing traffic.",
    partial: true,
  },
  {
    date: "2016-06-01T22:00:00.000Z",
    minutes: 60,
    details: "Network instability resulting in errors, and databases failing over.",
    partial: true,
  },
  {
    date: "2016-08-10T22:00:00.000Z",
    minutes: 42,
    details: "High request latency and possible connection errors.",
    partial: true,
  },
  {
    date: "2016-09-08T22:00:00.000Z",
    minutes: 20,
    details: "<p>Partial HTTP traffic failures. During this time, the application was unable to receive HTTP requests.</p><p>A malfunction in the routing infrastructure caused routing nodes to get stuck in a crash loop. The malfunction was caused by a change to the router update mechanism that triggered a previously unknown defect in the router’s ability to recover from failures.</p><p>We are working to improve both the update and crash recovery mechanism for our routing infrastructure. As we improve these systems we are also going to re-examine the systems themselves to improve monitoring and alerting, with the ultimate goal of replacing the router update mechanism to improve reliability.</p><p>As this incident was caused by a previously unknown defect, we are also going to improve our response playbooks to allow us to detect this before production, diagnose as issues arise and respond to this issue if we were to see it again.</p>",
    partial: false,
  },
  {
    date: "2016-10-20T22:00:00.000Z",
    minutes: 90,
    details: "Some traffic was unable to reach them due to a DNS outage due to DDoS attack against one of our DNS providers.",
    partial: true,
  },
  {
    date: "2016-11-02T23:00:00.000Z",
    minutes: 90,
    details: "Spike network latency causing connections, network outages, DNS lookup failures and decreased throughput on network connections.",
    partial: true,
  },
  {
    date: "2016-11-27T23:00:00.000Z",
    minutes: 13,
    details: "Increased latency and some reduced availability.",
    partial: true,
  },
  {
    date: "2016-11-27T23:00:00.000Z",
    minutes: 19,
    details: "Partial HTTP traffic failures and increased latency.",
    partial: true,
  },
  {
    date: "2016-12-14T23:00:00.000Z",
    minutes: 7,
    details: "Reduction in availability due to failed DNS lookups, leading to intermittent failures.",
    partial: true,
  },
  {
    date: "2017-03-13T23:00:00.000Z",
    minutes: 769,
    details: "Elevated H19 and H21 error rates in the EU region. Impacted approximately 1% of total requests in the EU region.",
    partial: true,
  },
  {
    date: "2017-04-03T22:00:00.000Z",
    minutes: 106,
    details: "Increased rate of H10 App Crashed errors.",
    partial: true,
  },
  {
    date: "2017-04-25T22:00:00.000Z",
    minutes: 8,
    details: "Increased rate of H10 App Crashed errors.",
    partial: true,
  },
  {
    date: "2017-05-16T22:00:00.000Z",
    minutes: 8,
    details: "New SSL endpoints can’t be created.",
    partial: true,
  },
  {
    date: "2017-06-06T22:00:00.000Z",
    minutes: 13,
    details: "Decreased routing availability, including incoming connection timeouts, H11s, and H12s.",
    partial: true,
  },
  {
    date: "2017-07-02T22:00:00.000Z",
    minutes: 3,
    details: "Reduced availability of the routing layer for apps using SSL.",
    partial: true,
  },
  {
    date: "2017-07-09T22:00:00.000Z",
    minutes: 31,
    details: "Increased dyno latency that may lead to H11 errors.",
    partial: true,
  },
  {
    date: "2017-07-24T22:00:00.000Z",
    minutes: 45,
    details: "Network degradation showing increased errors including H12, H13, and H19.",
    partial: true,
  },
  {
    date: "2017-08-08T22:00:00.000Z",
    minutes: 45,
    details: "Identified periods of increased latency and timeouts for apps using SSL.",
    partial: true,
  },
  {
    date: "2017-08-16T22:00:00.000Z",
    minutes: 45,
    details: "<p>Experienced HTTP/S traffic failures and increased latency for a period of approximately 80 seconds.</p><p>During this period, 1 in 10 requests to the affected applications timed out. Applications continued to run, but were unable to receive some of their intended HTTP/S requests.</p><p>This issue affected traffic over the SNI and non-SNI main stack load balancers. Additionally, a very small number of applications using SSL Endpoints were affected.</p>",
    partial: false,
  },
  {
    date: "2017-08-16T22:00:00.000Z",
    minutes: 2,
    details: "<p>Experienced HTTP/S traffic failures and increased latency for a period of approximately 80 seconds.</p><p>During this period, 1 in 10 requests to the affected applications timed out. Applications continued to run, but were unable to receive some of their intended HTTP/S requests.</p><p>This issue affected traffic over the SNI and non-SNI main stack load balancers. Additionally, a very small number of applications using SSL Endpoints were affected.</p>",
    partial: false,
  },
  {
    date: "2017-11-15T23:00:00.000Z",
    minutes: 31,
    details: "Increased rate of H10 App Crashed errors.",
    partial: true,
  },
  {
    date: "2017-11-21T23:00:00.000Z",
    minutes: 25,
    details: "Some requests via SSL have been experiencing an increase in latency. Affected users may also have experienced an increase in request timeouts and/or failed requests.",
    partial: true,
  },
  {
    date: "2017-12-12T23:00:00.000Z",
    minutes: 24,
    details: "Some requests via SSL have been experiencing an increase in latency. Affected users may also have experienced an increase in request timeouts and/or failed requests.",
    partial: true,
  },
  {
    date: "2018-02-28T23:00:00.000Z",
    minutes: 20,
    details: "DNS resolution failures leading to an increase in levels of error rates.",
    partial: true,
  },
  {
    date: "2018-04-19T22:00:00.000Z",
    minutes: 303,
    details: "Increase in response times, Increase in H19 - Backend connection timeout errors, Delays in routing recently added domains.",
    partial: true,
  },
  {
    date: "2018-04-30T22:00:00.000Z",
    minutes: 38,
    details: "Increased latency for our SNI endpoints.",
    partial: true,
  },
  {
    date: "2018-06-13T22:00:00.000Z",
    minutes: 46,
    details: "Request to domains pointing to a number of SSL Endpoints are currently resulting in 'No Such App' errors.",
    partial: true,
  },
  {
    date: "2018-10-08T22:00:00.000Z",
    minutes: 81,
    details: "Engineers have observed an increase in routing latency.",
    partial: true,
  },
  {
    date: "2018-10-24T22:00:00.000Z",
    minutes: 3,
    details: "Experienced failures in routing requests to apps, as well as H27 errors.",
    partial: true,
  },
  {
    date: "2019-05-13T22:00:00.000Z",
    minutes: 94,
    details: "Our DNS provider which serves DNS for our DNS targets is experiencing networking issues in their South America region.",
    partial: true,
  },
  {
    date: "2019-06-04T22:00:00.000Z",
    minutes: 15,
    details: "Traffic to applications was dropped due to a incorrect configuration in the load balancers.",
    partial: false,
  },
  {
    date: "2019-07-22T22:00:00.000Z",
    minutes: 143,
    details: "Elevated latency routing requests to applications.",
    partial: true,
  },
  {
    date: "2019-11-29T23:00:00.000Z",
    minutes: 40,
    details: "Elevated latency routing requests to applications.",
    partial: true,
  },
  {
    date: "2020-04-26T22:00:00.000Z",
    minutes: 10,
    details: "Our CDN provider was impacted by high error rates across multiple regions. This led to the application being intermittently unavailable.",
    partial: true,
  },
  {
    date: "2020-06-14T22:00:00.000Z",
    minutes: 8,
    details: "Increased latency on our data service led to the application being intermittently unavailable.",
    partial: true,
  },
  {
    date: "2020-07-16T22:00:00.000Z",
    minutes: 7,
    details: "DNS resolution failures lead to an increase in levels of error rates.",
    partial: true,
  },
  {
    date: "2020-08-10T22:00:00.000Z",
    minutes: 120,
    details: "Traffic to customer applications was intermittently disrupted.",
    partial: false,
  },
  {
    date: "2020-09-27T22:00:00.000Z",
    minutes: 4,
    details: "Elevated latency routing requests to applications.",
    partial: true,
  },
  {
    date: "2020-10-25T23:00:00.000Z",
    minutes: 120,
    details: "Traffic to customer applications was intermittently disrupted between 16:39 CET and 19:42 CET due to a distributed denial-of-service (DDoS) attack on a supplier.",
    partial: false,
  },
  {
    date: "2020-11-07T23:00:00.000Z",
    minutes: 25,
    details: "An application exhausting resources. Our failover mechnism was activated, allowing us to quickly redirect traffic.",
    partial: false,
  },
  {
    date: "2020-11-14T23:00:00.000Z",
    minutes: 5,
    details: "Scheduled deployment impacted the performance of the application.",
    partial: true,
  },
  {
    date: "2021-01-06T23:00:00.000Z",
    minutes: 38,
    details: "Traffic to customer applications was intermittently disrupted between 08:08 CET and 09:00 CET due to an application exhausting resources.",
    partial: false,
  },
  {
    date: "2021-01-13T23:00:00.000Z",
    minutes: 5,
    details: "Elevated latency routing requests to applications.",
    partial: true,
  },
  {
    date: "2021-01-19T23:00:00.000Z",
    minutes: 16,
    details: "An application exhausting resources. Our failover mechnism was activated, allowing us to quickly redirect traffic.",
    partial: false,
  },
  {
    date: "2021-01-21T23:00:00.000Z",
    minutes: 5,
    details: "Elevated latency routing requests to applications.",
    partial: true,
  },
  {
    date: "2021-08-04T23:00:00.000Z",
    minutes: 2,
    details: "Degraded routing availability.",
    partial: true,
  },
  {
    date: "2021-11-23T23:00:00.000Z",
    minutes: 2,
    details: "Degraded routing availability.",
    partial: true,
  },
  {
    date: "2021-11-25T23:00:00.000Z",
    minutes: 2,
    details: "Degraded routing availability.",
    partial: true,
  },
  {
    date: "2022-08-22T23:00:00.000Z",
    minutes: 45,
    details: "Issue with DNS resolution.",
    partial: false,
  },
  {
    date: "2022-08-23T23:00:00.000Z",
    minutes: 55,
    details: "Issue with DNS resolution.",
    partial: false,
  },
  {
    date: "2022-08-25T23:00:00.000Z",
    minutes: 26,
    details: "Issue with DNS resolution.",
    partial: true,
  },
  {
    date: "2022-08-29T23:00:00.000Z",
    minutes: 80,
    details: "Issue with DNS resolution.",
    partial: true,
  },
  {
    date: "2022-09-24T23:00:00.000Z",
    minutes: 14,
    details: "Database upgrade.",
    partial: false,
  },
  {
    date: "2022-10-22T23:00:00.000Z",
    minutes: 4,
    details: "Issue with DNS resolution.",
    partial: true,
  },
  {
    date: "2022-10-28T23:00:00.000Z",
    minutes: 4,
    details: "Issue with DNS resolution.",
    partial: true,
  },
  {
    date: "2023-07-25T13:55:00.000Z",
    minutes: 6,
    details: "Deployment rollback.",
    partial: false,
  },
  {
    date: "2023-11-08T23:00:00.000Z",
    minutes: 6,
    details: "Issues with upstream DNS provider.",
    partial: false,
  },
]
