import './App.css';
import Header from './components/Header';
import Tabs from './components/Tabs';

function App() {
  return (
    <div className="App container mx-auto p-5">
      <Header />
      <Tabs />
    </div>
  );
}

export default App;
