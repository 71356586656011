import moment from "moment";
import { formatTimespan } from "../../utils";

function getInfo(dt, incidents) {
  let color, label

  if (incidents.length === 0) {
    color = "bg-green-400";
    label = `: Available.`;
  } else {
    let result = { sum: 0, partial: true }
    result = incidents.reduce((res, inc) => {
      res['sum'] = res['sum'] + inc.minutes
      res['partial'] = res['partial'] && inc.partial
      return res
    }, result)
    
    if (result.partial) {
      color = "bg-yellow-400"
      label = `: ${formatTimespan(result.sum)} reduced performance.`
    } else {
      color = "bg-red-400";
      label = `: ${formatTimespan(result.sum)} unavailable.`
    }
  }

  return { color, label }
}

function TooltipCircle({ dt, incidents }) {

  const { color, label } = getInfo(dt, incidents)

  return (
    <div className="relative flex flex-col items-center group">
      <div className={`w-4 h-4 rounded-full ${color}`} />
      <div className="absolute bottom-4 flex-col items-center hidden bg-6 group-hover:flex">
        <span className="relative z-10 p-3 text-xs rounded leading-none text-white whitespace-no-wrap bg-black shadow-lg w-48">
          <span className="font-semibold">{moment(dt).format("MMM Do")}</span><span>{label}</span>
        </span>
        <div className="w-3 h-3 -mt-2 rotate-45 bg-black" />
      </div>
    </div>
  );
}

export default TooltipCircle;